import { defineStore } from 'pinia';

// Represents the buying options available for a product in Magento
export enum BuyingOptionEnum {
  FOR_DELIVERY = 'DEFAULT',
  IN_BOND = 'IN_BOND'
}

export interface BuyingOptionInterface {
  type: string;
  label: string;
  isSelected: boolean;
  isAvailable: boolean;
}

interface BuyingOptionStoreInterface {
  mode: BuyingOptionInterface;
  modes: BuyingOptionInterface[];
}

export const useBuyingOption = defineStore('buyingOption', {
  state: () => <BuyingOptionStoreInterface>({
    modes: [{
      type: BuyingOptionEnum.FOR_DELIVERY,
      label: 'Delivery',
      isSelected: true,
      isAvailable: true,
    }, {
      type: BuyingOptionEnum.IN_BOND,
      label: 'Storage In Bond',
      isSelected: false,
      isAvailable: true,
    }],
  }),
  getters: {
    isInBondSelected(state: BuyingOptionStoreInterface): () => boolean {
      return () => state.modes.find((option) => option.isSelected).type === BuyingOptionEnum.IN_BOND;
    },
    isForDeliverySelected(state: BuyingOptionStoreInterface): () => boolean {
      return () => state.modes.find((option) => option.isSelected).type === BuyingOptionEnum.FOR_DELIVERY;
    },
    getSelectedMode(state: BuyingOptionStoreInterface): () => BuyingOptionInterface | undefined {
      return () => state.modes.find((option) => option.isSelected);
    },
    getModeByType(state: BuyingOptionStoreInterface): (type: string) => BuyingOptionInterface {
      return (type: string) => state.modes.find((option) => option.type === type);
    },
    getFirstAvailable(state: BuyingOptionStoreInterface): () => BuyingOptionInterface {
      return () => state.modes.find((option) => option.isAvailable);
    }
  },
  actions: {
    switchModeByType(option: BuyingOptionInterface) {
      if (option.isAvailable) {
        this.toggleModeByType(option.type);
      }
    },
    updateDeliveryAvailability(isAvailable: boolean) {
      this.updateModeAvailability(BuyingOptionEnum.FOR_DELIVERY, isAvailable);
    },
    updateInBondAvailability(isAvailable: boolean) {
      this.updateModeAvailability(BuyingOptionEnum.IN_BOND, isAvailable);
    },
    updateModeAvailability(type: string, isAvailable: boolean) {
      this.modes = this.modes.map((option: BuyingOptionInterface) => {
        if (option.type === type) {
          option.isAvailable = isAvailable;
        }

        return option;
      });
      this.validateModes();
    },
    toggleModeByType(type: string) {
      this.modes = this.modes.map((option: BuyingOptionInterface) => ({
        ...option,
        isSelected: type === option.type,
      }));
    },
    validateModes() {
      const firstAvailable = this.getFirstAvailable();
      this.toggleModeByType(firstAvailable?.type || '');
    },
  },
});
