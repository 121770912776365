

















































































import {
  SfCarousel,
  SfSection,
  SfLoader,
  SfProductCard,
} from '@storefront-ui/vue';
import { defineComponent, PropType, toRefs, useContext } from '@nuxtjs/composition-api';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import { ConfigurableVariant, useImage } from '~/composables';
import type { Product } from '~/modules/catalog/product/types';
import {
  ProductWithCommonProductCardProps,
  useProductsWithCommonProductCardProps
} from '~/modules/catalog/category/components/views/useProductsWithCommonCardProps';
import { prepareImgUrl } from '~/bbrTheme/helpers/prepareImgUrl';
import { getAttributeValue } from '~/bbrTheme/modules/catalog/getters/productGetters';
import CategoryProductPrice from '~/modules/catalog/category/components/views/CategoryProductPrice.vue';
import { FamilyType } from '~/bbrTheme/modules/catalog/stores/product';

export default defineComponent({
  name: 'ProductsCarousel',
  components: {
    SfCarousel,
    SfSection,
    SfLoader,
    CategoryProductPrice,
    SfProductCard,
    Accessories: () => import('~/bbrTheme/modules/catalog/category/components/views/grid/family/Accessories.vue'),
    AssortmentMixedCases: () =>
      import('~/bbrTheme/modules/catalog/category/components/views/grid/family/AssortmentMixedCases.vue'),
    Books: () => import('~/bbrTheme/modules/catalog/category/components/views/grid/family/Books.vue'),
    BundleMixedCase: () =>
      import('~/bbrTheme/modules/catalog/category/components/views/grid/family/BundleMixedCase.vue'),
    Glassware: () => import('~/bbrTheme/modules/catalog/category/components/views/grid/family/Glassware.vue'),
    OtherDrinks: () => import('~/bbrTheme/modules/catalog/category/components/views/grid/family/OtherDrinks.vue'),
    Spirits: () => import('~/bbrTheme/modules/catalog/category/components/views/grid/family/Spirits.vue'),
    TicketedEvents: () => import('~/bbrTheme/modules/catalog/category/components/views/grid/family/TicketedEvents.vue'),
    Wines: () => import('~/bbrTheme/modules/catalog/category/components/views/grid/family/Wines.vue'),
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    products: {
      type: Array as PropType<Product[]>,
      required: false,
      default: () => [],
    },
    loading: Boolean,
  },
  setup(props) {
    const { products } = toRefs(props);
    const { productsWithCommonProductCardProps } = useProductsWithCommonProductCardProps(products);
    const context = useContext();

    const getImgUrl = (product) => prepareImgUrl(context, product);

    const getConfigurableVariant = (product: ProductWithCommonProductCardProps): ConfigurableVariant | null => {
      if (product.__typename !== 'ConfigurableProduct') return null;

      return product?.variants?.[0] || null;
    }

    const getComponentName = (product: ProductWithCommonProductCardProps): string => {
      // replace is used to remove spaces since for some families we get names with spaces from Akeneo
      const familyType = getAttributeValue(product, 'family_type') as FamilyType || FamilyType.Accessories;

      return familyType.replace(/ /g, '');
    };

    const { imageSizes: { productCard: imageSize } } = useImage();

    return {
      productGetters,
      imageSize,
      getImgUrl,
      productsWithCommonProductCardProps,
      getComponentName,
      getConfigurableVariant,
    };
  },
});
