import { ref } from '@nuxtjs/composition-api';
import { ConfigurableVariant } from '~/composables';
import type { Product } from '~/modules/catalog/product/types';
import { getName, getPrice } from '~/modules/catalog/product/getters/productGetters';
import { getAttributeValue } from '~/bbrTheme/modules/catalog/getters/productGetters';
import { AddToCartNotificationInterface } from '~/bbrTheme/modules/catalog/types';
import { useUiState } from '~/bbrTheme/modules/catalog/composables/useUiState';
import { ProductAttributeInterface } from '~/bbrTheme/modules/catalog/types';

export const useAddToCartNotification = () => {
  const addedProduct = ref<AddToCartNotificationInterface | null>(null);
  const { isAddToCartNotification, toggleAddToCartNotification } = useUiState();

  const setAddedProduct = (notification: AddToCartNotificationInterface | null) => {
    addedProduct.value = notification;
  };

  const resetAddedProduct = () => {
    setAddedProduct(null);
  };

  const displayAddToCartNotification = ({
    isAdded,
    product,
    variant,
    quantity,
  }: {
    isAdded: boolean;
    product: Product,
    variant: ConfigurableVariant | null,
    quantity: number,
  }) => {
    if (isAdded) {
      resetAddedProduct();
      const attributes = [] as ProductAttributeInterface[];

      // Add bottle volume and case order unit to attributes
      if (variant) {
        const bottleVolume = getAttributeValue(variant.product, 'bottle_volume');
        const caseOrderUnit = getAttributeValue(variant.product, 'case_order_unit');

        if (bottleVolume && caseOrderUnit) {
          attributes.push({
            label: caseOrderUnit,
            value: bottleVolume,
          });
        }
      }

      // Set notification data
      setAddedProduct({
        sku: product.sku,
        name: getName(variant?.product || product),
        qty: quantity || 1,
        price: getPrice(variant?.product || product),
        mainImage: getAttributeValue(product, 'main_image'),
        familyType: getAttributeValue(product, 'family_type'),
        attributes,
      });

      // Show notification sidebar
      toggleAddToCartNotification();
    }
  }

  return {
    addedProduct,
    isAddToCartNotification,
    toggleAddToCartNotification,
    setAddedProduct,
    resetAddedProduct,
    displayAddToCartNotification,
  }
};
