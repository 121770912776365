var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SfSection',{staticClass:"section"},[(_vm.title)?_c('h2',{staticClass:"carousel-title"},[_vm._v("\n    "+_vm._s(_vm.title)+"\n  ")]):_vm._e(),_vm._v(" "),_c('SfLoader',{class:{ loading: _vm.loading },attrs:{"loading":_vm.loading}},[_c('SfCarousel',{staticClass:"carousel grid-layout",class:{
        'multiple-products': _vm.productsWithCommonProductCardProps.length >= 2 ,
        'two-products': _vm.productsWithCommonProductCardProps.length === 2,
      },attrs:{"data-cy":"related-products-carousel","settings":{
        type: 'slider',
        peek: 0,
        perView: 3,
        breakpoints: {
          1023: { peek: 0, perView: _vm.productsWithCommonProductCardProps.length === 1 ? 1 : 2 },
          992: { peek: 0, perView: _vm.productsWithCommonProductCardProps.length === 1 ? 1 : 2 },
          575: { peek: 0, perView: 1 }
        },
        gap: 19,
      }},scopedSlots:_vm._u([{key:"prev",fn:function(prevArrow){return [_c('div',{staticClass:"arrow prev",attrs:{"aria-label":"previous"},on:{"click":function($event){return prevArrow.go('prev')}}})]}},{key:"next",fn:function(nextArrow){return [_c('div',{staticClass:"arrow next",attrs:{"aria-label":"next"},on:{"click":function($event){return nextArrow.go('next')}}})]}}])},_vm._l((_vm.productsWithCommonProductCardProps),function(product){return _c('SfCarouselItem',{key:product.uid,staticClass:"carousel__item"},[_c('SfProductCard',_vm._b({staticClass:"card",attrs:{"image":_vm.getImgUrl(product),"data-testid":"product-card","image-height":_vm.imageSize.height,"image-width":_vm.imageSize.width,"add-to-cart-disabled":product.stock_status === 'OUT_OF_STOCK',"show-add-to-cart-button":false},on:{"click:wishlist":function($event){return _vm.$emit('click:wishlist', product)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c(_vm.getComponentName(product),{tag:"component",attrs:{"variant":_vm.getConfigurableVariant(product),"product":product}})]},proxy:true},{key:"price",fn:function(){return [_c('noscript',[_vm._v(_vm._s(_vm.$t('Disabled price slot')))])]},proxy:true},{key:"reviews",fn:function(){return [_c('noscript',[_vm._v(_vm._s(_vm.$t('Disabled reviews slot')))])]},proxy:true}],null,true)},'SfProductCard',product.commonProps,false))],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }