




















import {
  useContext,
  useFetch,
  defineComponent,
  onMounted,
  ref
} from '@nuxtjs/composition-api';
import { useContent } from '~/modules/amplience/composables/useContent';
import { SortEnum, useProduct } from '~/composables';
import ProductsCarousel from '~/bbrTheme/modules/catalog/product/components/ProductsCarousel.vue';

export default defineComponent({
  name: 'HomePage',
  components: {
    ProductsCarousel,
  },
  setup() {
    const { getProductList } = useProduct();
    const { $config } = useContext();
    const { search, data } = useContent();
    const products = ref<any[]>([]);

    useFetch(async () => {
      await search('home', 'url');
    });

    onMounted(async () => {
      const newestProducts = await getProductList({
        pageSize: 8,
        currentPage: 1,
        sort: {
          position: SortEnum.Asc,
        },
      });

      if (newestProducts?.items?.length) {
        products.value = newestProducts.items;
      }
    });

    return {
      data,
      products,
      baseURL: $config.baseURL,
    };
  }
});
